import React, { useContext, useState } from "react";
import "./SolutionPoints.css";
import "swiper/css";
import { solutionPagePoints } from "./solutionPoints.data";
import { Accordion } from "react-bootstrap";
import SolutionPoint from "./SolutionPoint/SolutionPoint";
import ActiveContent from "./ActiveContent/ActiveContent";
import SolutionPointMobile from "./SolutionPoint/SolutionPointMobile";
import ThemeContext from "../../context/themeContext";

const SolutionPoints = () => {
  const [activeContent, setActiveContent] = useState(
    solutionPagePoints[0].content
  );
  const [activeKey, setActiveKey] = useState(0);

  const { isMobile } = useContext(ThemeContext);

  const handleMouseEnter = (index, content) => {
    setActiveKey(index);
    setActiveContent(content);
  };

  return (
    <section className="section_fit">
      <div className="container">
        <div className="pb-3">
          <h2 className="section-heading fw-bold text-center text-dark">
            Explore <span className="text-brand">#iSnagger</span>
          </h2>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-6 col-sm-12">
            <div className="active-contents">
              {isMobile ? (
                <swiper-container
                  class="mySwiper"
                  pagination="true"
                  pagination-clickable="true"
                >
                  {solutionPagePoints.map((point) => (
                    <swiper-slide key={point.id}>
                      <ActiveContent className={"mx-2"} title={point.content} />
                      <SolutionPointMobile point={point} />
                    </swiper-slide>
                  ))}
                </swiper-container>
              ) : (
                <ActiveContent title={activeContent} />
              )}
            </div>
          </div>
          {!isMobile && (
            <div className="col-xl-6 col-md-6">
              <Accordion id="solutionPoints" activeKey={activeKey}>
                {solutionPagePoints.map((point, index) => (
                  <SolutionPoint
                    key={point.id}
                    index={index}
                    point={point}
                    handleMouseEnter={handleMouseEnter}
                  />
                ))}
              </Accordion>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default SolutionPoints;
