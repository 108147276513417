const customers = [
  {
    id: 1,
    src: "/assets/images/customer-logos/grey/tribute.png",
    alt: "tribute",
  },
  {
    id: 2,
    src: "/assets/images/customer-logos/grey/cofounderslab.png",
    alt: "cofounderslab",
  },
  {
    id: 3,
    src: "/assets/images/customer-logos/grey/incu.png",
    alt: "incu",
  },
  {
    id: 4,
    src: "/assets/images/customer-logos/grey/hikomore.png",
    alt: "hikomore",
  },
  {
    id: 5,
    src: "/assets/images/customer-logos/grey/moreld.png",
    alt: "moreld",
  },
  {
    id: 6,
    src: "/assets/images/customer-logos/grey/taskmaster.png",
    alt: "taskmaster",
  },
  {
    id: 7,
    src: "/assets/images/customer-logos/grey/volly.png",
    alt: "volly",
  },
  {
    id: 8,
    src: "/assets/images/customer-logos/grey/747-live.png",
    alt: "747-live",
  },
  {
    id: 9,
    src: "/assets/images/customer-logos/grey/q-auditor.png",
    alt: "q-auditor",
  },
  {
    id: 10,
    src: "/assets/images/customer-logos/grey/moreld-apply.png",
    alt: "moreld-apply",
  },
  {
    id: 11,
    src: "/assets/images/customer-logos/grey/c2perform.png",
    alt: "c2perform",
  },
  {
    id: 12,
    src: "/assets/images/customer-logos/grey/recruiteze.png",
    alt: "recruiteze",
  },
];

export { customers };
