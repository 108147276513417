import React from "react";
import BrandButton from "../../components/BrandButton";

const PricingWelcome = () => {
  return (
    <div className="container">
      <div className="py-5 text-center">
        <h5 className="fw-bold fs-1 ">
          Explore <span className="text-brand">{`iSnagger's`}</span> Powerful
          Capabilities
        </h5>
        <div className="pt-3">
          <BrandButton>Start your risk free 21-Day Trial</BrandButton>
        </div>
      </div>
    </div>
  );
};

export default PricingWelcome;
