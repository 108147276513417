import React from "react";
import About from "../../containers/About/About";
import AboutUsGettingStart from "../../containers/AboutUsGettingStart/AboutUsGettingStart";
import BookDemoForm from "../../containers/BookDemoForm/BookDemoForm";

const AboutUsPage = () => {
  return (
    <>
      <About />
      <AboutUsGettingStart />
      <BookDemoForm />
    </>
  );
};

export default AboutUsPage;
