import React from "react";
import { socialMedias } from "../../layout/Footer/data/footer.data";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  HELPLINE_NUMBER,
  TROVE_CODE,
  TROVE_CODE_URL,
} from "../../helpers/constants";
import "./CopyRight.css";

const CopyRight = () => {
  return (
    <div className="copyright-section">
      <div className="row copyright align-item-center">
        <div className="col-md-6">
          <p className="mb-0">
            Copyright &#169;{" "}
            <Link
              className="text-decoration-none text-white"
              target="_blank"
              to={TROVE_CODE_URL}
            >
              {TROVE_CODE}
            </Link>
          </p>
        </div>
        <div className="col-md-6 social-media text-end">
          <ul className="social-icons">
            <span className="phone-number px-2 fs-6">
              <a
                className="text-decoration-none"
                href={`tel:${HELPLINE_NUMBER.trim()}`}
              >
                <FaPhoneAlt /> {HELPLINE_NUMBER}
              </a>
            </span>
            {socialMedias.map((socialMedia) => (
              <li key={socialMedia.id}>
                <a href={socialMedia.href} target="_blank" rel="noreferrer">
                  {socialMedia.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
