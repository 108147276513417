export const keyFeaturePoints = [
  {
    id: "1",
    feature: "Snag Item Recording",
  },
  {
    id: "2",
    feature: "Team Collaboration",
  },
  {
    id: "3",
    feature: "Reporting and Analytics",
  },
  {
    id: "4",
    feature: "Integration with Existing Tools",
  },
  {
    id: "5",
    feature: "Customization and Configuration",
  },
  {
    id: "6",
    feature: "Real Time Notification",
  },
  {
    id: "7",
    feature: "Audit Trail",
  },
  {
    id: "8",
    feature: "User Permission and Access Control",
  },
  {
    id: "9",
    feature: "Cross Platfrom Compatibility",
  },
  {
    id: "10",
    feature: "Offline Data Sync",
  },
];
