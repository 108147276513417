import React from "react";
import { Accordion, AccordionHeader, AccordionItem } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SolutionPoint = (props) => {
  const { point, handleMouseEnter, index } = props;
  const { id, title, content } = point;

  return (
    <AccordionItem className="py-2" eventKey={index}>
      <AccordionHeader onMouseEnter={() => handleMouseEnter(index, content)}>
        <div className="flex d-flex gap-5">
          <div className="flex-col fs-6 fw-bold">{id}</div>
          <div className="flex-col solution-point-title">{title}</div>
        </div>
      </AccordionHeader>
      <Accordion.Body>
        <div className="ps-3">
          <Link className="text-brand fw-bold fs-6">Learn more</Link>
        </div>
      </Accordion.Body>
    </AccordionItem>
  );
};

SolutionPoint.propTypes = {
  point: PropTypes.object,
  index: PropTypes.number,
  handleMouseEnter: PropTypes.func,
};

export default SolutionPoint;
