import React from "react";
import SolutionOverview from "../../containers/SolutionOverview/SolutionOverview";
import SolutionPoints from "../../containers/SolutionPoints/SolutionPoints";
import KeyBenefits from "../../containers/KeyBenefits/KeyBenefits";
import BookDemoForm from "../../containers/BookDemoForm/BookDemoForm";
import DemoCall from "../../containers/DemoCall/DemoCall";
import SolutionGettingStart from "../../containers/SolutionGettingStart/SolutionGettingStart";

const SolutionPage = () => {
  return (
    <div>
      <SolutionOverview />
      <SolutionPoints />
      <DemoCall />
      <KeyBenefits />
      <SolutionGettingStart />
      <BookDemoForm />
    </div>
  );
};

export default SolutionPage;
