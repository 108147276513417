import React from "react";
import FeaturesBanner from "../../containers/FeaturesBanner/FeaturesBanner";
import Features from "../../containers/Features/Features";
import KeyFeatures from "../../containers/KeyFeatures/KeyFeatures";
import FeatureGettingStart from "../../containers/FeatureGettingStart/FeatureGettingStart";
import BookDemoForm from "../../containers/BookDemoForm/BookDemoForm";

const FeaturesPage = () => {
  return (
    <div>
      <FeaturesBanner />
      <Features />
      <KeyFeatures />
      <FeatureGettingStart />
      <BookDemoForm />
    </div>
  );
};

export default FeaturesPage;
