import React, { useEffect, useState } from "react";
import Brand from "./components/Brand";
import NavbarMenus from "./components/NavbarMenus";
import "./Navbar.css";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 50);
    });
  }, []);

  return (
    <header
      className={`navbar navbar-expand-md bg-white sticky-top ${
        isScrolled ? "sticky" : ""
      }`}
    >
      <div className="container-inner d-flex">
        <Brand />
        <NavbarMenus />
      </div>
    </header>
  );
};

export default Navbar;
