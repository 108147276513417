import { GrCloudComputer } from "react-icons/gr";
import { FaPeopleGroup } from "react-icons/fa6";
import {
  BiSolidReport,
  BiSolidDevices,
  BiSolidCustomize,
} from "react-icons/bi";
import { FaTools, FaUserLock } from "react-icons/fa";
import { MdNotificationsActive, MdOfflinePin } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";

export const featuresData = [
  {
    id: "1",
    icon: <GrCloudComputer />,
    heading: "Efficient Snag Item Recording",
    description:
      "Digitize and simplify the snagging process with iSnagger's intuitive interface, allowing users to identify, record, and categorize defects quickly and accurately.",
  },
  {
    id: "2",
    icon: <FaPeopleGroup />,
    heading: "Seamless Team Collaboration",
    description:
      "Foster collaboration among project teams, contractors, and stakeholders with iSnagger's real-time communication and task assignment features, ensuring everyone stays informed and aligned throughout the project lifecycle.",
  },
  {
    id: "3",
    icon: <BiSolidReport />,
    heading: "Advanced Reporting and Analytics",
    description:
      "Gain valuable insights into project progress and defect trends with iSnagger's robust reporting and analytics tools, enabling informed decision-making and proactive issue resolution.",
  },
  {
    id: "4",
    icon: <FaTools />,
    heading: "Integration with Existing Tools",
    description:
      "Enhance workflow efficiency by seamlessly integrating iSnagger with your existing project management and collaboration tools, creating a unified ecosystem for streamlined project management.",
  },
  {
    id: "5",
    icon: <BiSolidCustomize />,
    heading: "Customization and Configuration",
    description:
      "Tailor iSnagger to fit your unique project requirements with customizable settings and configurations, ensuring flexibility and adaptability to your specific needs.",
  },
  {
    id: "6",
    icon: <MdNotificationsActive />,
    heading: "Real-Time Notifications",
    description:
      "Stay updated on project developments and snagging activities with iSnagger's real-time notifications, ensuring prompt awareness of changes or updates.",
  },
  {
    id: "7",
    icon: <AiOutlineAudit />,
    heading: "Audit Trail",
    description:
      "Maintain a comprehensive audit trail of all snagging activities for accountability and transparency, tracking all actions and changes made within iSnagger.",
  },
  {
    id: "8",
    icon: <FaUserLock />,
    heading: "User Permission and Access Control",
    description:
      "Manage user permissions and access levels to safeguard sensitive project data and ensure secure collaboration among team members.",
  },
  {
    id: "9",
    icon: <BiSolidDevices />,
    heading: "Cross-Platform Compatibility",
    description:
      "Access iSnagger seamlessly across different devices and platforms, enabling you to work anytime, anywhere, without compromising functionality.",
  },
  {
    id: "10",
    icon: <MdOfflinePin />,
    heading: "Offline Data Access",
    description:
      "Continue snagging work even in areas with limited or no internet connectivity with iSnagger's offline data access feature, ensuring uninterrupted productivity and efficiency on-site.",
  },
];
