import React from "react";
import ContentRoutes from "./ContentRoutes";

const Content = () => {
  return (
    <main className="content">
      <ContentRoutes />
    </main>
  );
};

export default Content;
