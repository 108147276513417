import React from "react";
import AlertBanner from "../../containers/AlertBanner/AlertBanner";
import Banner from "../../containers/Banner/Banner";
import About from "../../containers/About/About";
import Customers from "../../containers/Customers/Customers";
import Solution from "../../containers/Solution/Solution";
import ProductOverview from "../../containers/ProductOverview/ProductOverview";
import DemoCall from "../../containers/DemoCall/DemoCall";
import Statistics from "../../containers/Statistics/Statistics";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div>
      <main className="main-wrapper">
        <AlertBanner />
        <Banner />
        <About isDark={true} />
        <Customers />
        <Solution />
        <ProductOverview />
        <DemoCall />
        <Statistics />
      </main>
    </div>
  );
};

export default HomePage;
