import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import { ThemeContextProvider } from "./context/themeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import ScrollToTop from "./helpers/scrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextProvider>
    <Router>
      <React.StrictMode>
        <ScrollToTop />
        <App />
      </React.StrictMode>
    </Router>
  </ThemeContextProvider>
);
