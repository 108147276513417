export const TROVE_CODE = "TROVE CODE PVT LTD";
export const TCPL_TAGLINE = "One Stop Solution To Everything Coding";
export const HELPLINE_NUMBER = "+91 70003 32485";
export const MAX_NOTIFICATION_COUNT = 3;
export const ISNAGGER_URL = "https://stg.isnagger.in";
export const ISNAGGER_DEMO_URL = "https://demo.isnagger.in";
export const TROVE_CODE_URL = "http://trovecode.in/";
export const MOBILE_WIDTH = 480;
export const SMALL_TABLET_WIDTH = 767;
export const TABLET_WIDTH = 991;
export const MOBILE_NUMBER = "+917314870419";
export const MOBILE_NUMBER_STRING = "(+91) 731-4870419";
export const EMAIL = "info@trovecode.com";
export const ADDRESS =
  "B-817, Tirumala Town, Indore, Madhya Pradesh 452006, India";
export const MAPS_SEARCH = "https://g.page/trovecode?share";
export const FACEBOOK_LINK =
  "https://www.facebook.com/profile.php?id=100094423700575";
export const INSTAGRAM_LINK = "https://www.instagram.com/isnagger.in/";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/isnagger/";
