import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Accordion, AccordionHeader, AccordionItem } from "react-bootstrap";
import { faqData } from "./faq.data";
import "./FAQPage.css";

const FAQPage = () => {
  return (
    <Container className="section_fit justify-content-center">
      <Box className="h-100">
        <Stack className="gap-3 text-center">
          <h2 className="section-heading">
            You have <span className="text-brand"> Question</span>? We have
            <span className="text-brand"> Answers</span>.
          </h2>
        </Stack>
        <Stack className="py-3 px-3 text-center">
          <Typography
            sx={{ fontFamily: '"Noto Sans", sans-serif' }}
            variant="h6"
          >
            If you&apos;ve recently purchased a newly constructed home,
            you&apos;ve likely heard about snagging inspections. But what
            exactly do they entail? This FAQ aims to answer your most common
            questions about snagging inspections, offering a clearer picture of
            this important step in acquiring your new home.
          </Typography>
        </Stack>
      </Box>
      <Box className="h-100 py-3">
        <Accordion id="faqAccordion">
          {faqData.map((faq) => (
            <AccordionItem eventKey={faq.id} key={faq.id}>
              <AccordionHeader>{faq.question}</AccordionHeader>
              <Accordion.Body>{faq.answer}</Accordion.Body>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Container>
  );
};

export default FAQPage;
