import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import PlanFeatureRow from "./PlanFeatureRow";

const PlanCard = (props) => {
  const {
    title,
    description,
    amountMonthly,
    amountYearly,
    unit,
    features,
    actionText,
    period,
    buttonPath,
  } = props;

  const navigate = useNavigate();

  return (
    <section
      className={`price-card h-100 price-page-card ${
        title === "Personal" ? "professional" : "business"
      }`}
    >
      <div className="price-content">
        <div className="price-cover">
          <h4 className="price-title">{title}</h4>
          <div className="price-description">{description}</div>
          <div className="price-amount">
            <div className="currency">$</div>
            <div className="amount">
              {period === "monthly" ? amountMonthly : amountYearly}
            </div>
            <div className="unit">/{unit}</div>
          </div>
        </div>
        <div className="slider">
          <div className="requests">
            {features.monthlyRequests} monthly requests
          </div>
        </div>
        <ul className="price-feature">
          {features.items.map((item, index) => (
            <PlanFeatureRow key={index} feature={item}></PlanFeatureRow>
          ))}
        </ul>
        <div className="price-action">
          <button
            className="btn btn-primary w-100"
            onClick={() => navigate(buttonPath)}
          >
            {actionText}
          </button>
        </div>
      </div>
    </section>
  );
};

PlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amountMonthly: PropTypes.number.isRequired,
  amountYearly: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  features: PropTypes.shape({
    monthlyRequests: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  actionText: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
};

export default PlanCard;
