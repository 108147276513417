import React from "react";
import { useNavigate } from "react-router-dom";
import { formPages } from "../../pages";
import BrandButton from "../../components/BrandButton";
import "./AboutUsGettingStart.css";

const AboutUsGettingStart = () => {
  const navigate = useNavigate();

  return (
    <section className="about-us-getting-start-section text-center ">
      <div className="container">
        <h2 className="section-heading text-white">
          Experience the Difference with{" "}
          <span className="text-brand">TROVE CODE </span>{" "}
        </h2>
        <div className="py-3">
          Experience the difference with TROVE CODE today and discover how our
          innovative solutions can transform your business. Get in touch with us
          to learn more about our products and services or to schedule a demo of
          iSnagger. Together, let&apos;s redefine what&apos;s possible and
          create a brighter tomorrow.
        </div>
        <div className="align-self-center pt-3">
          <BrandButton onClick={() => navigate(`${formPages.bookADemo.path}`)}>
            Book a 15 minute discovery call
          </BrandButton>
        </div>
      </div>
    </section>
  );
};

export default AboutUsGettingStart;
