import React from "react";
import { PropTypes } from "prop-types";

const ProductOverviewPoint = (props) => {
  const { productOverviewPoint } = props;

  return (
    <div
      className="product-overview-body-item mt-40 item-animation wow fadeInLeft"
      data-wow-duration=".5s"
      data-wow-delay=".4s"
    >
      <span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
        </svg>
      </span>
      <h6 className="title">{productOverviewPoint}</h6>
    </div>
  );
};

ProductOverviewPoint.propTypes = {
  productOverviewPoint: PropTypes.string,
};

export default ProductOverviewPoint;
