import React from "react";
import PlansList from "../../containers/Pricing/PlansList";
import PricingWelcome from "../../containers/PricingWelcome/PricingWelcome";
// import PlanComparisonTable from "../../containers/Pricing/PlanComparison/PlanComparisonTable";
// import { planComparisonData } from "../../containers/Pricing/plans.data";

const PricingPage = () => {
  return (
    <>
      <PricingWelcome />
      <PlansList />
      {/* <PlanComparisonTable data={planComparisonData} /> */}
    </>
  );
};

export default PricingPage;
