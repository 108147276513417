import { formPages } from "../../../pages";

export const plansData = [
  {
    title: "Free",
    description: "For Testing",
    amountMonthly: 0,
    amountYearly: 0,
    unit: "mo",
    features: {
      monthlyRequests: 200,
      items: [
        {
          isIncluded: true,
          text: "Unlimited email templates",
        },
        {
          isIncluded: true,
          text: "Whitelist",
        },
        {
          isIncluded: true,
          text: "Unlimited contacts",
        },
        {
          isIncluded: false,
          text: "Attachments up to 30mb",
        },
        {
          isIncluded: false,
          text: "Completely white label",
        },
        {
          isIncluded: false,
          text: "Multi-user access",
        },
        {
          isIncluded: false,
          text: "Priority support",
        },
      ],
    },
    actionText: "Try for free",
    buttonPath: formPages.freeTrial.path,
  },
  {
    title: "Personal",
    description: "For Personal Use",
    amountMonthly: 11,
    amountYearly: 9,
    unit: "mo",
    features: {
      monthlyRequests: 2000,
      items: [
        {
          isIncluded: true,
          text: "Unlimited email templates",
        },
        {
          isIncluded: true,
          text: "Whitelist",
        },
        {
          isIncluded: true,
          text: "Unlimited contacts",
        },
        {
          isIncluded: true,
          text: "Attachments up to 30mb",
        },
        {
          isIncluded: false,
          text: "Completely white label",
        },
        {
          isIncluded: false,
          text: "Multi-user access",
        },
        {
          isIncluded: false,
          text: "Priority support",
        },
      ],
    },
    actionText: "Try for free",
    buttonPath: formPages.freeTrial.path,
  },
  {
    title: "Professional",
    description: "Entrepreneurs & Freelancers",
    amountMonthly: 19,
    amountYearly: 15,
    unit: "mo",
    features: {
      monthlyRequests: 10000,
      items: [
        {
          isIncluded: true,
          text: "Unlimited email templates",
        },
        {
          isIncluded: true,
          text: "Whitelist",
        },
        {
          isIncluded: true,
          text: "Unlimited contacts",
        },
        {
          isIncluded: true,
          text: "Attachments up to 30mb",
        },
        {
          isIncluded: true,
          text: "Completely white label",
        },
        {
          isIncluded: true,
          text: "Multi-user access",
        },
        {
          isIncluded: false,
          text: "Priority support",
        },
      ],
    },
    actionText: "Try for free",
    buttonPath: formPages.freeTrial.path,
  },
  {
    title: "Business",
    description: "Best for Small Business",
    amountMonthly: 50,
    amountYearly: 40,
    unit: "mo",
    features: {
      monthlyRequests: " > 10k",
      items: [
        {
          isIncluded: true,
          text: "Unlimited email templates",
        },
        {
          isIncluded: true,
          text: "Whitelist",
        },
        {
          isIncluded: true,
          text: "Unlimited contacts",
        },
        {
          isIncluded: true,
          text: "Attachments up to 30mb",
        },
        {
          isIncluded: true,
          text: "Completely white label",
        },
        {
          isIncluded: true,
          text: "Multi-user access",
        },
        {
          isIncluded: true,
          text: "Priority support",
        },
      ],
    },
    actionText: "Contact us",
    buttonPath: formPages.contactUs.path,
  },
];

export const planComparisonData = [
  {
    name: "Free",
    price: "$0/mo",
    features: [
      {
        name: "Monthly Requests",
        description:
          "The maximum number of requests that can be sent to our service within a month.",
        values: ["200", "2,000", "5,000", "25,000"],
      },
      {
        name: "Email Templates",
        description: "The maximum number of email templates.",
        values: ["2", "6", "Unlimited", "Unlimited"],
      },
      {
        name: "Attachments Size",
        description: "Total size of attachments per request.",
        values: ["-", "500kb", "2mb", "30mb"],
      },
      {
        name: "Multi-user access",
        description: "Maximum number of users in the account.",
        values: ["1 seat", "1 seat", "3 seats", "Unlimited"],
      },
    ],
  },
  {
    name: "Personal",
    price: "$11/mo",
    features: [
      {
        name: "Monthly Requests",
        description:
          "The maximum number of requests that can be sent to our service within a month.",
        values: ["200", "2,000", "5,000", "25,000"],
      },
      {
        name: "Email Templates",
        description: "The maximum number of email templates.",
        values: ["2", "6", "Unlimited", "Unlimited"],
      },
      {
        name: "Attachments Size",
        description: "Total size of attachments per request.",
        values: ["-", "500kb", "2mb", "30mb"],
      },
      {
        name: "Multi-user access",
        description: "Maximum number of users in the account.",
        values: ["1 seat", "1 seat", "3 seats", "Unlimited"],
      },
    ],
  },
  {
    name: "Professional",
    price: "$19/mo",
    features: [
      {
        name: "Monthly Requests",
        description:
          "The maximum number of requests that can be sent to our service within a month.",
        values: ["200", "2,000", "5,000", "25,000"],
      },
      {
        name: "Email Templates",
        description: "The maximum number of email templates.",
        values: ["2", "6", "Unlimited", "Unlimited"],
      },
      {
        name: "Attachments Size",
        description: "Total size of attachments per request.",
        values: ["-", "500kb", "2mb", "30mb"],
      },
      {
        name: "Multi-user access",
        description: "Maximum number of users in the account.",
        values: ["1 seat", "1 seat", "3 seats", "Unlimited"],
      },
    ],
  },
  {
    name: "Business",
    price: "$50/mo",
    features: [
      {
        name: "Monthly Requests",
        description:
          "The maximum number of requests that can be sent to our service within a month.",
        values: ["200", "2,000", "5,000", "25,000"],
      },
      {
        name: "Email Templates",
        description: "The maximum number of email templates.",
        values: ["2", "6", "Unlimited", "Unlimited"],
      },
      {
        name: "Attachments Size",
        description: "Total size of attachments per request.",
        values: ["-", "500kb", "2mb", "30mb"],
      },
      {
        name: "Multi-user access",
        description: "Maximum number of users in the account.",
        values: ["1 seat", "1 seat", "3 seats", "Unlimited"],
      },
    ],
  },
];
