export const featuresBannerPoints = [
  {
    id: "feature-banner-1",
    feature: "Fragmented snagging processes leading to delays and errors.",
  },
  {
    id: "feature-banner-2",
    feature: "Manual and time-consuming workflows impacting efficiency.",
  },
  {
    id: "feature-banner-3",
    feature: "Financials OR Cost Saving.",
  },
  {
    id: "feature-banner-4",
    feature: "Customer Pain Points.",
  },
];
