import React, { useState } from "react";
import { PropTypes } from "prop-types";

const PricingPlanSwitch = ({ handlePeriodChange }) => {
  const [period, setPeriod] = useState("monthly");

  const handleSwitch = () => {
    const newPeriod = period === "monthly" ? "yearly" : "monthly";
    setPeriod(newPeriod);
    handlePeriodChange(newPeriod);
  };

  return (
    <div className="pricing-plan-wrapper">
      <section className="ejs-switch-button pricing-plan-switch">
        <label htmlFor="price-period-switch">
          <span
            className={`pricing-plan-select ${
              period === "monthly" ? "pricing-plan-right" : ""
            }`}
          >
            Monthly
          </span>
        </label>
        <label htmlFor="price-period-switch" className="ejs-switch">
          <input
            type="checkbox"
            id="price-period-switch"
            className="ejs-switch-input"
            onChange={handleSwitch}
            checked={period === "yearly"}
          />
          <div className="ejs-switch-pan"></div>
        </label>
        <label htmlFor="price-period-switch">
          <span
            className={`pricing-plan-select ${
              period === "yearly" ? "pricing-sale" : ""
            }`}
          >
            Yearly <span className="pricing-sale">20% OFF</span>
          </span>
        </label>
      </section>
    </div>
  );
};

PricingPlanSwitch.propTypes = {
  handlePeriodChange: PropTypes.func.isRequired,
};

export default PricingPlanSwitch;
