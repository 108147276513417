import React from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

const SolutionPointMobile = (props) => {
  const { point } = props;
  const { title } = point;

  return (
    <div className="flex flex-col text-center gap-5 fs-4 fw-bold pt-3 pb-5">
      <div className="flex-col">{title}</div>
      <Link className="text-brand fw-bold fs-6">Learn more</Link>
    </div>
  );
};

SolutionPointMobile.propTypes = {
  point: PropTypes.object,
  index: PropTypes.number,
  handleMouseEnter: PropTypes.func,
};

export default SolutionPointMobile;
