import React from "react";
import { formPages, landingPages } from "../pages";
import HomePage from "../pages/home/HomePage";
import AboutUsPage from "../pages/about-us/AboutUsPage";
import SolutionPage from "../pages/solution/SolutionPage";
import FeaturesPage from "../pages/features/FeaturesPage";
import FAQPage from "../pages/faq/FAQPage";
import BookADemoPage from "../pages/book-a-demo/BookADemoPage";
import FreeTrial from "../pages/free-trial/FreeTrial";
import PricingPage from "../pages/pricing/PricingPage";
import ContactUsPage from "../pages/contact-us/ContactUsPage";

const LANDING = {
  HOME: HomePage,
  ABOUT: AboutUsPage,
  SOLUTION: SolutionPage,
  FEATURES: FeaturesPage,
  FAQ: FAQPage,
  BOOK_A_DEMO: BookADemoPage,
  FREE_TRIAL: FreeTrial,
  PRICING: PricingPage,
  CONTACT_US: ContactUsPage,
};

const contents = [
  {
    path: landingPages.home.path,
    element: <LANDING.HOME />,
  },
  {
    path: landingPages.aboutUs.path,
    element: <LANDING.ABOUT />,
  },
  {
    path: landingPages.solution.path,
    element: <LANDING.SOLUTION />,
  },
  {
    path: landingPages.features.path,
    element: <LANDING.FEATURES />,
  },
  {
    path: landingPages.faq.path,
    element: <LANDING.FAQ />,
  },
  {
    path: formPages.bookADemo.path,
    element: <LANDING.BOOK_A_DEMO />,
  },
  {
    path: formPages.freeTrial.path,
    element: <LANDING.FREE_TRIAL />,
  },
  {
    path: formPages.pricing.path,
    element: <LANDING.PRICING />,
  },
  {
    path: formPages.contactUs.path,
    element: <LANDING.CONTACT_US />,
  },
];

export default contents;
