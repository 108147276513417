import React from "react";
import { AlertBannerContent } from "./alertBanner.data";
import { HELPLINE_NUMBER } from "../../helpers/constants";
import { FaPhoneAlt } from "react-icons/fa";
import "./AlertBanner.css";

const AlertBanner = () => {
  return (
    <div className="d-flex justify-content-center py-1 alert-banner">
      <div className="alert-banner-text py-1">
        <a
          className="text-decoration-none text-white"
          href={`tel:${HELPLINE_NUMBER.trim()}`}
        >
          <FaPhoneAlt className="mx-2" />
          {AlertBannerContent}
        </a>
      </div>
    </div>
  );
};

export default AlertBanner;
