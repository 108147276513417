import React from "react";

const Brand = () => {
  return (
    <a className="navbar-brand" href="/">
      <img src="/assets/images/logos/isnagger_dark.png" alt="Logo" />
    </a>
  );
};

export default Brand;
