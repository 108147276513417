export const faqData = [
  {
    id: "1",
    question: "What is Snagging?",
    answer:
      "Snagging involves identifying and documenting any issues, defects, or incomplete work in a newly built home. This includes structural concerns and problems with fixtures and fittings.",
  },
  {
    id: "2",
    question: "Can I Do Snagging Myself?",
    answer:
      "Yes, you can tackle snagging on your own, but it's recommended to hire a professional for a thorough inspection. Professionals have the expertise and a neutral eye to catch even the smallest issues that homeowners might overlook. You have the option to do snagging yourself, and our app makes it easier. Our user-friendly app, iSnagger, helps you perform snagging inspections independently, ensuring a comprehensive and accurate assessment.",
  },
  {
    id: "3",
    question: "Why Do I Need a Snagging Inspection?",
    answer:
      "Ensures your new home meets quality standards and is free from defects. Protects your investment by addressing issues before they become major problems. Holds the developer accountable for fixing any identified faults. The Snagging Inspection Process A professional inspector thoroughly examines the property, inside and out. They create a detailed report with photographs highlighting every issue. You can use this report to discuss necessary repairs or improvements with the developer.",
  },
  {
    id: "4",
    question: "How Does Snagging Work?",
    answer:
      "While many people can spot obvious defects, not everyone can identify all the issues a professional can. Snagging companies come equipped with checklists to ensure everything meets the required standards. After the inspection, you'll receive a detailed report to provide to your builder.",
  },
  {
    id: "5",
    question: "Why Are Snagging Inspections Necessary for New Builds?",
    answer: `Newly constructed homes involve the efforts of various individuals in different weather conditions, making snagging inspections essential. Despite quality control efforts, oversight can occur, leading to lower quality and attention to detail.`,
  },
  {
    id: "6",
    question: "What is a Snag?",
    answer:
      "A snag refers to a minor defect in your newly constructed home, typically identified after construction is completed. These issues range from small scratches to more significant problems, like poorly fitted pipes.",
  },
  // {
  //   id: "7",
  //   question: "Who Can Use iSnagger?",
  //   answer:
  //     "While iSnagger may not have a specific priority field, users can communicate the urgency or priority of a snag issue through the title or description. Project managers can then prioritize and assign resources accordingly.",
  // },
  // {
  //   id: "8",
  //   question: "Can I add attachments or photos to a snag issue?",
  //   answer:
  //     "iSnagger is designed for professionals and teams across various industries involved in defect management, including civil construction, real estate development, infrastructure projects, and more.",
  // },
  // {
  //   id: "9",
  //   question:
  //     "How do I communicate with other team members or stakeholders about a snag issue?",
  //   answer:
  //     "iSnagger typically provides communication features such as comments or messaging within each snag issue. Use these features to collaborate, ask questions, or provide updates regarding a particular snag.",
  // },
  // {
  //   id: "10",
  //   question: 'What happens when a snag issue is marked as "Resolved"?',
  //   answer:
  //     'When a snag issue is marked as "Resolved," it indicates that the issue has been successfully addressed. Users can review the resolution details, and if satisfied, close the snag. If not, they may reopen it for further attention.',
  // },
  {
    id: "11",
    question: "Have more questions?",
    answer:
      "Feel free to contact us for further assistance. We are here to help!",
  },
];
