import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { formPages, landingPages } from "../../../pages";
import BrandButton from "../../../components/BrandButton";

const NavbarMenus = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const location = useLocation();

  const handleMenuOptionClick = () => {
    if (showDrawer) {
      setShowDrawer(false);
    }
  };

  const toggleNavbarDrawer = () => {
    setShowDrawer((prevState) => !prevState);
  };

  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={toggleNavbarDrawer}
      >
        <span className="toogle-button" />
        <span className="toogle-button" />
        <span className="toogle-button" />
      </button>
      <div
        className={`collapse navbar-collapse ${showDrawer ? "show" : ""}`}
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav text-nowrap">
          {Object.keys(landingPages).map((pageKey) => {
            const page = landingPages[pageKey];

            return (
              <li
                key={page.id}
                className={`nav-item-with-active nav-item ${
                  location.pathname === page.path ? "active" : ""
                }`}
              >
                <Link
                  className="text-decoration-none"
                  id={`#${page.id}`}
                  to={page.path}
                  onClick={() => handleMenuOptionClick(page.path)}
                >
                  {page.label}
                </Link>
              </li>
            );
          })}
          <li className="nav-item">
            <Link
              className="btn-link text-decoration-none"
              to={formPages.bookADemo.path}
              onClick={() => handleMenuOptionClick(formPages.bookADemo.path)}
            >
              <BrandButton>Book a Demo</BrandButton>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavbarMenus;
