import React from "react";
import StatisticBox from "./components/StatisticBox";
import { statisticsData } from "./data/statistics.data";
import "./Statistics.css";

const Statistics = () => {
  return (
    <section className="fact-section section_fit">
      <div className="container">
        <h5 className="section-heading text-center">
          Company <span className="text-brand">Statistics</span>
        </h5>
        <div className="fact-boxes row pt-md-3 pt-0">
          {statisticsData.map((statisticItem) => (
            <div
              className="col-lg-3 col-md-6 col-sm-12 py-3"
              key={statisticItem.id}
            >
              <StatisticBox
                name={statisticItem.name}
                icon={statisticItem.icon}
                count={statisticItem.count}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Statistics;
