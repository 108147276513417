import React, { useState } from "react";
import PricingPlanSwitch from "./components/PlanSwitch";
import PlanCard from "./components/PlanCard";
import { plansData } from "./data/plans.data";
import "./PlansList.css";

const PlansList = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="container">
      <div className="pb-3 text-center">
        <PricingPlanSwitch handlePeriodChange={handlePeriodChange} />
      </div>
      <div className="prices row g-3 h-100 pb-5">
        {plansData.map((plan, index) => (
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" key={index}>
            <PlanCard {...plan} period={selectedPeriod} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlansList;
