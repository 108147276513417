import React from "react";
import PropTypes from "prop-types";

const CommonForm = (props) => {
  const { emailData, setEmailData, handleSubmit, isLoading } = props;

  const handleChange = (event) => {
    event.preventDefault();

    setEmailData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <form id="contact-us" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="firstName"
          className="form-control"
          id="first_name"
          placeholder="First Name"
          required
          value={emailData.firstName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="lastName"
          className="form-control"
          id="last_name"
          placeholder="Last Name"
          required
          value={emailData.lastName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group full-width">
        <input
          type="text"
          name="companyName"
          className="form-control"
          id="company_name"
          placeholder="Company Name"
          required
          value={emailData.companyName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          className="form-control"
          id="email"
          placeholder="Email"
          required
          value={emailData.email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <input
          type="tel"
          name="mobileNumber"
          className="form-control"
          maxLength="20"
          id="mobile"
          placeholder="Mobile No."
          required
          pattern="[6789][0-9]{9}"
          title="Please enter valid phone number"
          value={emailData.mobileNumber}
          onChange={handleChange}
        />
      </div>
      <div className="form-group full-width">
        <textarea
          className="form-control"
          name="message"
          id="message"
          placeholder="Message"
          required
          value={emailData.message}
          onChange={handleChange}
          maxLength={500}
        ></textarea>
        <div className="character-count">
          <span>{emailData.message.length}&nbsp;</span>
          <span>/ 500</span>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary rounded-pill w-50"
        disabled={isLoading}
      >
        {isLoading ? "Submitting " : "Sumit"}
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
      </button>
    </form>
  );
};

CommonForm.propTypes = {
  emailData: PropTypes.object,
  setEmailData: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CommonForm;
