import React from "react";
import { keyBenefitsData } from "./keyBenefits.data";
import KeyBenefit from "./KeyBenefit/KeyBenefit";
import "./KeyBenefits.css";

const KeyBenefits = () => {
  return (
    <section className="key-benefits-section section_fit">
      <div className="container">
        <h2 className="section-heading text-center">
          Key Benefits of <span className="text-brand">iSnagger</span>
        </h2>
        <div className="key-benefits pt-3 row">
          {keyBenefitsData.map((keyBenefit) => (
            <div
              className="col-xl-3 col-md-6 col-sm-12 py-3"
              key={keyBenefit.content}
            >
              <KeyBenefit
                icon={keyBenefit.icon}
                title={keyBenefit.title}
                content={keyBenefit.content}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyBenefits;
