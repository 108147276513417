import React from "react";
import Proptypes from "prop-types";
import { isMobile } from "../../helpers/methods";
import { TCPL_TAGLINE } from "../../helpers/constants";
import "./About.css";

const About = (props) => {
  const { isDark = false } = props;

  const isMobileDevice = isMobile();

  return (
    <section
      className={`about-section section_fit ${isDark ? "dark-section" : ""}`}
      id="about"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title mb-0">
              {/* <span>About Us</span> */}
              <h2 className="section-heading">
                Welcome to TROVE CODE - where innovation meets purpose.
              </h2>
            </div>
            <div className="about-body wow fadeInLeft">
              <p>
                Founded in 2020 as a powerhouse in software development, TROVE
                CODE is more than just a company; it&apos;s a vision. Our
                mission extends beyond conventional solutions, driven by the
                belief that code has the power to empower progress. <br />{" "}
                <br /> At TROVE CODE, we are committed to excellence and
                innovation. Our flagship product, iSnagger, stands as a
                testament to this commitment. More than groundbreaking software,
                iSnagger is a catalyst for revolutionizing industries,
                streamlining workflows, and making a lasting global impact—from
                inspections to seamless collaboration. <br /> <br />
                {/* With a focus on excellence and innovation, we provide a comprehensive
                solution to everything coding. Join us on this transformative
                journey, where technology meets purpose. Together, let&apos;s
                shape a future where every line of code contributes to
                transformative solutions. <br /> <br />  */}
                {TCPL_TAGLINE}
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div
              className={
                isMobileDevice
                  ? "about-image-container-mobile"
                  : "about-image-container"
              }
            >
              <img
                src="/assets/images/section-images/about_us_section.png"
                alt="about_us"
                className={isMobileDevice ? "" : "about-image"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = {
  isDark: Proptypes.bool,
};

export default About;
