import React from "react";
import { PropTypes } from "prop-types";

const PlanFeatureRow = (props) => {
  const { feature } = props;

  return (
    <div
      className="plan-feature-container g-3 mt-40 item-animation wow fadeInLeft"
      data-wow-duration=".5s"
      data-wow-delay=".4s"
    >
      {feature.isIncluded ? (
        <span>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
          </svg>
        </span>
      ) : (
        <span className="not-included">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	489.292,457.678 277.331,245.004 489.292,32.337 "
            />
          </svg>
        </span>
      )}
      <h6 className="plan-feature">{feature.text}</h6>
    </div>
  );
};

PlanFeatureRow.propTypes = {
  feature: {
    isIncluded: PropTypes.bool,
    text: PropTypes.string,
  },
};

export default PlanFeatureRow;
