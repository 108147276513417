export const solutionPagePoints = [
  {
    id: "01",
    title: "Construction Companies",
    content:
      "Discover how iSnagger caters to construction companies of all sizes, from small-scale contractors to large-scale enterprises. Whether you're managing a residential renovation or overseeing a major infrastructure project, iSnagger offers a comprehensive solution to streamline your snagging process. With customizable workflows and collaborative features, iSnagger empowers construction companies to efficiently manage snagging items and ensure timely project completion.",
  },
  {
    id: "02",
    title: "Individual Contractors",
    content:
      "iSnagger is the go-to solution for individual contractors looking to streamline their snagging process. With its user-friendly interface and intuitive features, iSnagger simplifies defect management and facilitates seamless collaboration with clients and subcontractors. Whether you're a carpenter, electrician, or plumber, iSnagger helps you stay organized and focused on delivering high-quality workmanship.",
  },
  {
    id: "03",
    title: "Project Managers",
    content:
      "Project managers rely on iSnagger to effectively oversee construction projects and ensure quality control. With its advanced reporting and analytics capabilities, iSnagger provides project managers with real-time insights into snagging trends and performance metrics. From tracking defect resolution timelines to coordinating tasks with team members, iSnagger empowers project managers to drive efficiency and accountability throughout the project lifecycle.",
  },
  {
    id: "04",
    title: "Quality Inspectors",
    content:
      "Quality inspectors trust iSnagger to conduct thorough inspections and maintain the highest standards of quality assurance. With its customizable inspection checklists and photo documentation features, iSnagger enables quality inspectors to identify and document defects with precision. Whether conducting pre-construction assessments or final walkthroughs, iSnagger equips quality inspectors with the tools they need to ensure project compliance and client satisfaction.",
  },
  // {
  //   id: "05",
  //   title: "Real Estate Developers",
  //   content:
  //     "Enhance defect management processes for real estate development projects, improving the quality of finished properties and customer satisfaction.",
  // },
  {
    id: "05",
    title: "Infrastructure Projects",
    content:
      "iSnagger plays a vital role in infrastructure projects by facilitating seamless snagging and defect management. Whether it's a highway expansion project or a utility infrastructure upgrade, iSnagger provides project teams with a centralized platform to track and address construction defects efficiently. With its mobile app and cloud-based platform, iSnagger ensures that infrastructure projects stay on track and on budget.",
  },
  {
    id: "06",
    title: "Coming Soon",
    content:
      "Stay tuned for updates on how iSnagger will revolutionize snagging and defect management in maintenance, production, hospitality, and hotel industries. With its versatile features and industry-specific solutions, iSnagger aims to streamline workflows and enhance productivity across various sectors. From facility maintenance to hotel renovations, iSnagger will offer tailored solutions to meet the unique needs of each industry.",
  },
];
