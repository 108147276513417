import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
} from "../../../helpers/constants";

export const socialMedias = [
  {
    id: "facebook",
    href: FACEBOOK_LINK,
    icon: <FaFacebookF />,
  },
  {
    id: "instagram",
    href: INSTAGRAM_LINK,
    icon: <FaInstagram />,
  },
  {
    id: "linkedin",
    href: LINKEDIN_LINK,
    icon: <FaLinkedinIn />,
  },
];
