import React from "react";
import { PropTypes } from "prop-types";

const ActiveContent = (props) => {
  const { title, className } = props;
  return (
    <div
      className={`active-content active-content-three text-center ${className}`}
    >
      <p className="counter">{title}</p>
    </div>
  );
};

ActiveContent.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ActiveContent;
