import React from "react";
import "./FeatureGettingStart.css";
import { useNavigate } from "react-router-dom";
import { formPages } from "../../pages";

const FeatureGettingStart = () => {
  const navigate = useNavigate();

  return (
    <section className="getting-start-section text-center">
      <div className="container">
        <h2 className="section-heading">
          Experience the Power of <span className="text-brand">iSnagger</span>{" "}
          Today!
        </h2>
        <div className="py-3">
          Explore the full range of features offered by iSnagger and discover
          how our powerful capabilities can streamline your snagging process and
          enhance project efficiency. Sign up for a free trial or schedule a
          demo with our team to experience iSnagger in action and take your
          projects to the next level.
        </div>
        <div className="align-self-center pt-3">
          <button
            className="btn btn-outline-dark rounded-pill fw-bold"
            onClick={() => navigate(`${formPages.freeTrial.path}`)}
          >
            Sign up for Free trial
          </button>
        </div>
      </div>
    </section>
  );
};

export default FeatureGettingStart;
