import { FaStopwatch, FaHandshake } from "react-icons/fa";
import { GiFlexibleStar } from "react-icons/gi";
import { IoAccessibility } from "react-icons/io5";

export const keyBenefitsData = [
  {
    id: "1",
    icon: <FaStopwatch />,
    title: "Efficiency",
    content:
      "Streamline snagging processes and reduce manual effort with digitized workflows and automated notifications.",
  },
  {
    id: "2",
    icon: <FaHandshake />,
    title: "Collaboration",
    content:
      "Faster collaboration among project teams, contractors, and stakeholders with real-time communication and task management features.",
  },
  //   {
  //     id: "3",
  //     icon: <GiMagnifyingGlass />,
  //     title: "Transparency",
  //     content:
  //       "Maintain transparency throughout the snagging process with comprehensive reporting and audit trail features, ensuring accountability and compliance.",
  //   },
  {
    id: "4",
    icon: <GiFlexibleStar />,
    title: "Flexibility",
    content:
      "Adapt iSnagger to fit your project requirements with customizable settings and configurations, providing flexibility to meet unique needs.",
  },
  {
    id: "5",
    icon: <IoAccessibility />,
    title: "Accessibility",
    content:
      "Access iSnagger anytime, anywhere, with cross-platform compatibility and offline data access, enabling teams to work seamlessly on-site or in remote locations.",
  },
];
