import React from "react";
import ProductOverviewPoint from "./components/ProductOverviewPoint";
import {
  overviewFeaturesPoints,
  overviewWorkPoints,
} from "./data/productOverview.data";
import "./ProductOverview.css";

const ProductOverview = () => {
  return (
    <section className="product-overview-section section_fit">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <span>Product Overview</span>
            </div>
            <div className="row g-3 product-overview-body">
              <div className="col-lg-12 col-sm-6 col-12">
                <h4 className="section-heading product-overview-sub-heading">
                  iSnagger Features
                </h4>
                {overviewFeaturesPoints.map((productOverviewPoint) => (
                  <div key={productOverviewPoint.id} className="col-12">
                    <ProductOverviewPoint
                      productOverviewPoint={productOverviewPoint.feature}
                    />
                  </div>
                ))}
              </div>
              <div className="col-lg-12 col-sm-6 col-12">
                <h4 className="section-heading product-overview-sub-heading">
                  How It Works
                </h4>
                {overviewWorkPoints.map((productOverviewPoint) => (
                  <div key={productOverviewPoint.id} className="col-12">
                    <ProductOverviewPoint
                      productOverviewPoint={productOverviewPoint.feature}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="product-overview-image-container">
              <img
                src="assets/images/section-images/product_overview_section.png"
                alt="product_overview"
                className="product-overview-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductOverview;
