import React, { useState } from "react";
import { send } from "@emailjs/browser";
import { messages } from "../../helpers/messages";
import "./FreeTrial.css";
import { useSnackbar } from "notistack";
import CommonForm from "../../components/CommonForm";

const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  mobileNumber: "",
  message: "",
};

const FreeTrial = () => {
  const [emailData, setEmailData] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const isValid = () => {
    const { firstName, lastName, companyName, email, mobileNumber, message } =
      emailData;

    if (
      !firstName ||
      !lastName ||
      !companyName ||
      !email ||
      !mobileNumber ||
      !message
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, companyName, email, mobileNumber, message } =
      emailData;

    if (isValid()) {
      const templateParams = {
        firstName,
        lastName,
        companyName,
        email,
        mobileNumber,
        message: message.trim(),
      };
      try {
        setisLoading(true);
        const response = await send(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_REQUEST_FREE_TRIAL_TEMPLATE_ID,
          templateParams,
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
        );
        console.log("response: ", response);
        if (response.status === 200) {
          enqueueSnackbar(messages.EMAIL_SENT_SUCCESSFULLY, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(messages.SOMETHING_WENT_WRONG, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar(messages.SOMETHING_WENT_WRONG, { variant: "error" });
      } finally {
        setisLoading(false);
        setEmailData(initialValues);
      }
    }
  };

  return (
    <section className="contact-section dark-section" id="contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 m-auto wow fadeInRight order-lg-2">
            <div className="contact-form">
              <h2 className="section-heading text-center mb-0">
                Register for Free Trial
              </h2>
              <div className="helper-text fs-6 text-center text-brand">
                Please fill below details to Register for Trial
              </div>
              <CommonForm
                emailData={emailData}
                setEmailData={setEmailData}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="col-lg-6 mt-5 order-lg-1 wow fadeInLeft">
            <div className="contact-vectore">
              <img
                src="/assets/images/section-images/banner_section.png"
                alt="book-demo"
                className="w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeTrial;
