import React from "react";
import { PropTypes } from "prop-types";

const StatisticBox = (props) => {
  const { name, count, icon } = props;
  return (
    <div className="fact-box fact-box-three text-center">
      <div className="icon">{icon}</div>
      <h6 className="counter">{count}</h6>
      <p className="title fw-bold">{name}</p>
    </div>
  );
};

StatisticBox.propTypes = {
  count: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.element,
};

export default StatisticBox;
