import React from "react";
import FeatureBannerPoint from "./components/FeatureBannerPoint";
import { featuresBannerPoints } from "./data/featuresBanner.data";
import "./FeaturesBanner.css";

const FeaturesBanner = () => {
  return (
    <section className="features-banner-section section_fit">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <span>Problem and Solution</span>
            </div>
            <div className="row g-3 features-banner-body">
              <div className="col-lg-12 col-sm-6 col-12">
                <h4 className="section-heading features-banner-sub-heading">
                  Problem
                </h4>
                {featuresBannerPoints.map((featureBannerPoint) => (
                  <div key={featureBannerPoint.id} className="col-12">
                    <FeatureBannerPoint
                      featureBannerPoint={featureBannerPoint.feature}
                    />
                  </div>
                ))}
              </div>
              <div className="col-lg-12 col-sm-6 col-12">
                <h4 className="section-heading features-banner-sub-heading">
                  Solution
                </h4>
                <div className="features-banner-sub-body wow fadeInLeft">
                  <p>
                    iSnagger provides a centralized platform for snag
                    management, real-time collaboration tools, and workflow
                    automation, addressing these challenges head-on. By
                    streamlining snag identification, enhancing communication,
                    and automating tasks, iSnagger ensures smoother project
                    handovers and increased efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="features-banner-image-container">
              <img
                src="assets/images/section-images/features_banner_section.png"
                alt="features_banner"
                className="features-banner-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBanner;
