import React from "react";
import { Link } from "react-router-dom";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import CopyRight from "../../containers/Copyright/Copyright";
import {
  ISNAGGER_URL,
  TROVE_CODE,
  TROVE_CODE_URL,
} from "../../helpers/constants";
import { formPages, landingPages } from "../../pages";
import { isMobile } from "../../helpers/methods";
import "./Footer.css";

const Footer = () => {
  const isMobileDevice = isMobile();

  return (
    <footer>
      <div className="container">
        <div className="row g-3 page-footer">
          <div className="col-lg-3 col-sm-12 footer-detail mt-0">
            <a href="#">
              <img
                src="/assets/images/logos/isnagger_light.png"
                alt="Logo"
                className="footer-logo"
              />
            </a>
            <Link
              className="text-decoration-none"
              target="_blank"
              to={TROVE_CODE_URL}
            >
              <p>&#169; {TROVE_CODE}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-sm-3 col-6 footer-links">
            <h6>legal</h6>
            <ul>
              <li>
                <Link
                  className="text-decoration-none"
                  to={`${ISNAGGER_URL}/terms`}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none"
                  to={`${ISNAGGER_URL}/privacy-policy`}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-3 col-6 footer-links">
            <h6>company</h6>
            <ul>
              {Object.keys(landingPages).map((pageKey) => {
                const page = landingPages[pageKey];

                return (
                  <li key={page.id}>
                    <Link className="text-decoration-none" to={page.path}>
                      {page.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-lg-2 col-sm-3 col-6 footer-links">
            <h6>question?</h6>
            <ul>
              <li>
                <Link
                  className="text-decoration-none"
                  to={formPages.contactUs.path}
                >
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none"
                  to={landingPages.home.path}
                >
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-3 col-6 footer-links">
            <h6>Download</h6>
            <ul>
              <li className="download-icon">
                <Link className="text-decoration-none">
                  <FaApple size={isMobileDevice ? 13 : 20} className="me-2" />
                  Get on App store
                </Link>
              </li>
              <li className="download-icon">
                <Link className="text-decoration-none">
                  <FaGooglePlay
                    size={isMobileDevice ? 10 : 15}
                    className="ms-1 me-2"
                  />
                  Get on Play Store
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <CopyRight />
      </div>
    </footer>
  );
};

export default Footer;
