import React from "react";
import "./SolutionGettingStart.css";
import BrandButton from "../../components/BrandButton";
import { Link } from "react-router-dom";
import { ISNAGGER_DEMO_URL } from "../../helpers/constants";

const SolutionGettingStart = () => {
  return (
    <section className="getting-start-section text-center">
      <div className="container">
        <h2 className="section-heading">
          Get Started with <span className="text-brand">iSnagger</span> Today!
        </h2>
        <div className="py-3">
          {` Experience the benefits of iSnagger and transform your snagging
          process for success. Sign up for a free trial or schedule a demo with
          our team to learn more about how iSnagger can streamline your
          construction projects. Don't let snagging issues slow you down. Join
          the iSnagger community and simplify your snagging process for improved
          project efficiency and success.`}
        </div>
        <div className="align-self-center pt-3">
          <Link target="_blank" to={ISNAGGER_DEMO_URL}>
            <BrandButton>Are you an exisiting user?</BrandButton>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SolutionGettingStart;
