import React from "react";
import { PropTypes } from "prop-types";

const Feature = (props) => {
  const { feature, className } = props;
  const { icon, heading } = feature;
  return (
    <div className={`feature feature-three text-center ${className}`}>
      <div className="icon">{icon}</div>
      <h6 className="counter">{heading}</h6>
      {/* <p className="title text-muted">{description}</p> */}
    </div>
  );
};

Feature.propTypes = {
  feature: PropTypes.object,
  className: PropTypes.string,
};

export default Feature;
