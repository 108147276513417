import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomerLogo from "./CustomerLogo/CustomerLogo";
import { customers } from "./data/Customers.data";
import { isMobile, isSmallTab, isTablet } from "../../helpers/methods";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import "./Customers.css";

const Customers = () => {
  const slidesPerView = () => {
    let slides = 8;

    if (isMobile()) {
      slides = 3;
    } else if (isTablet() || isSmallTab()) {
      slides = 5;
    }

    return slides;
  };

  return (
    <section className="customers-section">
      <div className="container">
        <Swiper
          spaceBetween={isMobile() ? 20 : 30}
          slidesPerView={slidesPerView()}
          freeMode={true}
          loop={true}
          speed={2500}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          className="swiper-wrapper"
        >
          {customers.map((customer) => (
            <SwiperSlide key={customer.id}>
              <CustomerLogo src={customer.src} alt={customer.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Customers;
