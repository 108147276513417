import React from "react";
import { SnackbarProvider } from "notistack";
import { register } from "swiper/element/bundle";
import Navbar from "../layout/Navbar/Navbar";
import Content from "../layout/Content/Content";
import Footer from "../layout/Footer/Footer";
import { MAX_NOTIFICATION_COUNT } from "../helpers/constants";

const App = () => {
  register();

  return (
    <SnackbarProvider
      maxSnack={MAX_NOTIFICATION_COUNT}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Navbar />
      <Content />
      <Footer />
    </SnackbarProvider>
  );
};

export default App;
