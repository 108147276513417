import React from "react";
import "./Solution.css";

const Solution = () => {
  return (
    <section className="solution-section dark-section section_fit">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="solution-image-container">
              <img
                src="/assets/images/section-images/solution_section.png"
                alt="solution-image"
                className="solution-image"
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="solution-body">
              <div className="section-title left-border mb-40">
                <span>What is iSnagger?</span>
                <h2 className="section-heading">
                  iSnagger - A Product by{" "}
                  <span className="text-brand">TROVE CODE</span>
                </h2>
              </div>
              <div className="solution-inner-body wow fadeInLeft">
                <p>
                  iSnagger is a user-friendly solution designed to streamline
                  snagging effortlessly across different areas. Whether
                  you&apos;re in civil construction, infrastructure projects, or
                  beyond, iSnagger is your go-to tool for simplifying and
                  enhancing snagging processes. More than just a tool, it&apos;s
                  a digital upgrade that revolutionizes how snagging works. It
                  empowers you to manage snagging items precisely, collaborate
                  easily with your team, and resolve issues promptly.
                  <br /> <br />
                  <strong>Vision:</strong> At TROVE CODE, we envisioned iSnagger
                  as more than just a solution; it&apos;s a catalyst for change
                  in the snagging industry.
                  <br /> <br />
                  <strong>Building the Future:</strong> iSnagger isn&apos;t just
                  a tool; it&apos;s a dynamic solution that adapts to unique
                  project needs, setting new standards for efficiency and
                  effectiveness.
                  <br /> <br />
                  <strong>Join the Movement:</strong> As we pave the way for a
                  new era in snagging, join us on this transformative journey.
                  Let&apos;s elevate project management standards together!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
