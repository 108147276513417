import React from "react";
import Proptypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { formPages } from "../pages";

const BrandButton = (props) => {
  const { className = "", children, onClick } = props;

  const navigate = useNavigate();

  return (
    <button
      className={`btn btn-primary rounded-pill ${className}`}
      onClick={onClick || (() => navigate(`${formPages.bookADemo.path}`))}
    >
      {children}
    </button>
  );
};

BrandButton.propTypes = {
  className: Proptypes.string,
  children: Proptypes.oneOfType([Proptypes.string, Proptypes.element]),
  onClick: Proptypes.func,
};

export default BrandButton;
