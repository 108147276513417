import React from "react";
import { PropTypes } from "prop-types";

const KeyBenefit = (props) => {
  const { title, content, icon, className } = props;
  return (
    <div className={`key-benefit key-benefit-three text-center ${className}`}>
      <div className="icon">{icon}</div>
      <h6 className="counter">{title}</h6>
      <p className="title text-muted">{content}</p>
    </div>
  );
};

KeyBenefit.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
};

export default KeyBenefit;
