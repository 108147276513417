import React from "react";
import { featuresData } from "./feature.data";
import Feature from "./Feature/Feature";
import "./Features.css";

const Features = () => {
  return (
    <section className="features-section section_fit">
      <div className="container">
        <h2 className="section-heading text-center py-4">
          Explore <span className="text-brand">{`iSnagger's`}</span> Powerful
          Capabilities.
        </h2>
        <div className="features row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-3">
          {featuresData.map((feature) => (
            <div className="col" key={feature.heading}>
              <Feature feature={feature} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
