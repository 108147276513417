import React from "react";
import { PropTypes } from "prop-types";
import "./CustomerLogo.css";

const CustomerLogo = (props) => {
  const { src, alt } = props;

  return (
    <div className="customer-logo-contaier">
      <div className="customer-logo-image">
        <img className="img-fluids" src={src} alt={alt} />
      </div>
    </div>
  );
};

CustomerLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default CustomerLogo;
