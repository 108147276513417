export const overviewFeaturesPoints = [
  {
    id: "1",
    feature: "Streamlined snag identification and reporting.",
  },
  {
    id: "2",
    feature: "Real-time collaboration tools for seamless communication.",
  },
  {
    id: "3",
    feature: "Task assignment and tracking capabilities.",
  },
  {
    id: "4",
    feature: "Analytics and reporting dashboard for project insights.",
  },
  {
    id: "5",
    feature: "Integration with other construction management systems.",
  },
];

export const overviewWorkPoints = [
  {
    id: "1",
    feature: "Capture snag details using the iSnagger.",
  },
  {
    id: "2",
    feature: "Assign tasks to team members for resolution.",
  },
  {
    id: "3",
    feature: "Track progress in real-time and manage workflow efficiently.",
  },
];
