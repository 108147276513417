import React from "react";
import { keyFeaturePoints } from "./data/keyFeatures.data";
import KeyFeature from "./components/KeyFeature";
import "./KeyFeatures.css";

const KeyFeatures = () => {
  return (
    <section className="key-features-section dark-section section_fit">
      <div className="container">
        <div className="pt-2">
          <div className="key-features-body wow fadeInLeft">
            <div className="row g-3 justify-content-center">
              <div className="col-xl-8 col-lg-12 col-md-12">
                <h2 className="section-heading py-4">
                  Key <span className="text-brand"> Features</span>
                </h2>
                <div className="row ">
                  {keyFeaturePoints.map((keyFeature, index) => (
                    <div
                      key={keyFeature.id}
                      className={`col-${index < 5 ? 6 : 6}`}
                    >
                      <KeyFeature keyFeature={keyFeature.feature} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-4 col-lg-8 col-md-10 d-none d-md-block">
                <img
                  src="assets/images/section-images/key_features_section.png"
                  className="h-100 w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;
