/* eslint-disable no-undef */
import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import useDeviceScreen from "../hooks/useDeviceScreen";

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const deviceScreen = useDeviceScreen();

  const isMobile =
    deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

  const isSmallTablet =
    deviceScreen?.width <= process.env.REACT_APP_SMALL_TAB_BREAKPOINT_SIZE;

  const isTablet =
    deviceScreen?.width <= process.env.REACT_APP_TABLET_BREAKPOINT_SIZE;

  const values = useMemo(
    () => ({
      isMobile,
      isSmallTablet,
      isTablet,
    }),
    [isMobile, isSmallTablet, isTablet]
  );

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContext;
