import React from "react";
import { useNavigate } from "react-router-dom";
import { formPages } from "../../pages";
import BrandButton from "../../components/BrandButton";
import "./Banner.css";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <section className="banner-section container section_fit" id="home">
      <div className="row justify-content-center align-items-center mx-0">
        <div className="col-md-6">
          <h2 className="banner-h2">
            Revolutionizing
            <span className="banner-span"> Snagging </span>
            for Smoother Handovers.
          </h2>
          <p className="banner-p">
            A versatile solution tailored for seamless snagging across diverse
            domains.
          </p>
          <div className="banner-btn-div">
            <a className="" onClick={() => navigate(formPages.bookADemo.path)}>
              <BrandButton>Book a Demo</BrandButton>
            </a>
            <button
              className="btn btn-outline-dark rounded-pill fw-bold"
              onClick={() => navigate(`${formPages.freeTrial.path}`)}
            >
              Free 21 day trial
            </button>
          </div>
        </div>
        <div className="col-md-6 banner-image-container">
          <img
            src="/assets/images/section-images/banner_section.png"
            alt="banner"
            className="banner-image"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
